<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else class="auto-reply-semantic-analysis">
      <div class="tw-h-full tw-w-full tw-flex tw-justify-center" v-if="!uuid">
        <div class="tw-m-auto tw-text-center">
          <v-icon size="96">{{ icons.mdiEmoticonSadOutline }}</v-icon>
          <UiSubtitle x-large :subtitle="$t('autoreply.semanticanalysis.nodata')" />
        </div>
      </div>
      <iframe v-else frameBorder="0" width="100%" height="100%" :src="computedSemanticAnalysisUrl"></iframe>
    </div>
  </v-fade-transition>
</template>

<script>
import { mdiEmoticonSadOutline } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { URLS } from '@/config/urls.config'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'AutoReplySemanticAnalysis',
  components: {
    UiSubtitle,
  },
  data: () => ({
    icons: {
      mdiEmoticonSadOutline,
    },
  }),
  async created() {
    this.setUpdating(true)
    await this.getUuid()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      uuid: state => state.autoreply.uuid,
    }),
    computedSemanticAnalysisUrl() {
      return `${URLS.SEMANTIC_URL}${this.uuid}/home`
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getUuid: 'autoreply/getUuid',
    }),
  },
}
</script>
